var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "Client Id", prop: "clientId" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入Client Id" },
                model: {
                  value: _vm.form.clientId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "clientId", $$v)
                  },
                  expression: "form.clientId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label:
                  "接口访问限制次数：该client每天訪問獲取token的次數限制(冗余字段)",
                prop: "numberLimit",
              },
            },
            [
              _c("a-input", {
                attrs: {
                  placeholder:
                    "请输入接口访问限制次数：该client每天訪問獲取token的次數限制(冗余字段)",
                },
                model: {
                  value: _vm.form.numberLimit,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "numberLimit", $$v)
                  },
                  expression: "form.numberLimit",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: { label: "当前的token：client当前的token", prop: "token" },
            },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入当前的token：client当前的token" },
                model: {
                  value: _vm.form.token,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "token", $$v)
                  },
                  expression: "form.token",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "当前访问次数：当日访问token接口的次数",
                prop: "numberCurrent",
              },
            },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入当前访问次数：当日访问token接口的次数",
                },
                model: {
                  value: _vm.form.numberCurrent,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "numberCurrent", $$v)
                  },
                  expression: "form.numberCurrent",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "当日日期", prop: "numberDate" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD HH:mm:ss",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.numberDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "numberDate", $$v)
                  },
                  expression: "form.numberDate",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "租户id", prop: "tenantId" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入租户id" },
                model: {
                  value: _vm.form.tenantId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "tenantId", $$v)
                  },
                  expression: "form.tenantId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "创建人", prop: "createdBy" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入创建人" },
                model: {
                  value: _vm.form.createdBy,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "createdBy", $$v)
                  },
                  expression: "form.createdBy",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "创建时间", prop: "createdTime" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD HH:mm:ss",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.createdTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "createdTime", $$v)
                  },
                  expression: "form.createdTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "更新人", prop: "updatedBy" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入更新人" },
                model: {
                  value: _vm.form.updatedBy,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "updatedBy", $$v)
                  },
                  expression: "form.updatedBy",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "更新时间", prop: "updatedTime" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD HH:mm:ss",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.updatedTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "updatedTime", $$v)
                  },
                  expression: "form.updatedTime",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }