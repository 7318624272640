<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="Client Id" prop="clientId" >
        <a-input v-model="form.clientId" placeholder="请输入Client Id" />
      </a-form-model-item>
      <a-form-model-item label="接口访问限制次数：该client每天訪問獲取token的次數限制(冗余字段)" prop="numberLimit" >
        <a-input v-model="form.numberLimit" placeholder="请输入接口访问限制次数：该client每天訪問獲取token的次數限制(冗余字段)" />
      </a-form-model-item>
      <a-form-model-item label="当前的token：client当前的token" prop="token" >
        <a-input v-model="form.token" placeholder="请输入当前的token：client当前的token" />
      </a-form-model-item>
      <a-form-model-item label="当前访问次数：当日访问token接口的次数" prop="numberCurrent" >
        <a-input v-model="form.numberCurrent" placeholder="请输入当前访问次数：当日访问token接口的次数" />
      </a-form-model-item>
      <a-form-model-item label="当日日期" prop="numberDate" >
        <a-date-picker style="width: 100%" v-model="form.numberDate" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="租户id" prop="tenantId" >
        <a-input v-model="form.tenantId" placeholder="请输入租户id" />
      </a-form-model-item>
      <a-form-model-item label="创建人" prop="createdBy" >
        <a-input v-model="form.createdBy" placeholder="请输入创建人" />
      </a-form-model-item>
      <a-form-model-item label="创建时间" prop="createdTime" >
        <a-date-picker style="width: 100%" v-model="form.createdTime" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="更新人" prop="updatedBy" >
        <a-input v-model="form.updatedBy" placeholder="请输入更新人" />
      </a-form-model-item>
      <a-form-model-item label="更新时间" prop="updatedTime" >
        <a-date-picker style="width: 100%" v-model="form.updatedTime" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getClientToken, addClientToken, updateClientToken } from '@/api/openapi/clientToken'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        clientId: null,
        numberLimit: null,
        token: null,
        numberCurrent: null,
        numberDate: null,
        tenantId: null,
        createdBy: null,
        createdTime: null,
        updatedBy: null,
        updatedTime: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        clientId: [
          { required: true, message: 'Client Id不能为空', trigger: 'blur' }
        ],
        numberLimit: [
          { required: true, message: '接口访问限制次数：该client每天訪問獲取token的次數限制(冗余字段)不能为空', trigger: 'blur' }
        ],
        token: [
          { required: true, message: '当前的token：client当前的token不能为空', trigger: 'blur' }
        ],
        numberCurrent: [
          { required: true, message: '当前访问次数：当日访问token接口的次数不能为空', trigger: 'blur' }
        ],
        numberDate: [
          { required: true, message: '当日日期不能为空', trigger: 'change' }
        ],
        tenantId: [
          { required: true, message: '租户id不能为空', trigger: 'blur' }
        ],
        createdBy: [
          { required: true, message: '创建人不能为空', trigger: 'blur' }
        ],
        createdTime: [
          { required: true, message: '创建时间不能为空', trigger: 'change' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        clientId: null,
        numberLimit: null,
        token: null,
        numberCurrent: null,
        numberDate: null,
        tenantId: null,
        createdBy: null,
        createdTime: null,
        updatedBy: null,
        updatedTime: null
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getClientToken(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateClientToken(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          } else {
            addClientToken(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
